@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto+Slab:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:wght@600&family=Roboto+Slab:wght@600&family=Rubik&display=swap);
.search__input {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 30px;
    padding: 10px 20px;
    border-radius: 50px;
    width: 75vw;
    max-width: 560px;
    margin: 0 auto;
    margin-top: 40px;
    background-color: rgb(60, 60, 62);
    border-radius: 24px;
    box-shadow: rgb(255 255 255 / 7%) 0px 0.5rem 1.5rem;
    border: 1px solid rgba(83, 83, 110, 0.25);
    transition: box-shadow 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s;
    font-size: 0.875rem;

   

}

.search__input > input {
    flex: 1 1;
    padding: 10px 20px;
    font-size: medium;
    background-color: transparent;
    color: rgb(248, 248, 248);
    border: none;
    font-family: 'Inter', sans-serif;
    z-index: 9;
}

.search__input > input:focus {
    outline-width: 0;
}

.search__inputIcon {
    color: gray;
}

.search__buttons {
    margin-top: 20px;
    display: flex;
    grid-gap: 2vh;
    gap: 2vh;
    justify-content: center;
}

.search__buttons .search__buttons__1 {
    padding: 0.5rem 1.25rem;
    border-radius: 50px;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 18px;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.15px;
    color: rgb(255, 255, 255);
    background: linear-gradient(98.89deg, rgb(50, 50, 253) 0%, rgb(107, 176, 255) 100%);
    margin-top: 18px;
    font-size: 15px;
    font-family: 'Roboto Slab', serif;
}
.search__buttons .search__buttons__2 {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        letter-spacing: -0.15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 48px;
        border: 1px solid rgba(21, 21, 110, 0.08);
        padding: 0.5rem 1rem;
        color: rgb(248, 248, 248);
        background-color: rgb(43, 43, 46);
        margin-top: 20px;
}

.search__buttons button:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
    background-color: #f8f8f8;
    border: 1px solid #c6c6c6;
    color: #222;
}

.search__buttonHidden {
    display: none !important;
}
.home { 
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: linear-gradient(to bottom,rgb(32 32 36),rgb(29, 29, 36));
    
}

.home__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    align-items: center;
}

.home__header a {
    margin-right: 20px;
    text-decoration: inherit;
    color: rgb(248, 248, 248);
    font-size: 15px;
    font-family: 'Roboto Slab', serif;
}

.home__header a:hover{
    text-decoration: underline;
}

.home__headerRight {
    display: flex;
    align-items: center;
    min-width: 13vw;
    justify-content: space-between;
}

.home__headerRight > .MuiAvatar-root {
    margin: 0 20px;
}

.home__body {
    flex: 1 1;
    display: flex;
    margin-top: 10%;
    flex-direction: column;
}

.home__body > img {
    object-fit: contain;
    height: 86px;
}
.login{
    min-width: 110px;
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: rgb(255, 255, 255);
    border-radius: 100px;
    border: 1px solid rgb(255, 255, 255);
    background: transparent;
    transition: background-color 0.3s ease-out 0s;
    height: 48px;
}
.about{
    text-align: center;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 28px;
    letter-spacing: -0.36px;
    margin-bottom: 2rem;
    color: rgb(248, 248, 248);
    margin-top: 41px;
}
.name{
    margin-right: 1rem;
    min-width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    color: rgb(255, 255, 255);
    border-radius: 100px;
    border: 0px solid transparent;
    background: linear-gradient(98.89deg, rgb(50, 50, 253) 0%, rgb(107, 176, 255) 100%);
    transition: background-color 0.3s ease-out 0s;
    height: 48px;
}


.circle_pattern {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    display: none;
    border-radius: 44px;
    background-color: rgb(60, 60, 62);
    border: 1px solid rgba(22, 22, 117, 0.15);
}
.gradient__upper{
    position: absolute;
    width: -webkit-fill-available;
    top: -20px;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {

   .ABout_store{
    display: none;
   }
   .circle_pattern{
    display: inline-flex;
   }
    
}
body {
  background: linear-gradient(to left, rgb(32 32 36), rgb(29, 29, 36));
}
.searchResult__header {
  background: linear-gradient(to bottom, rgb(32 32 36), rgb(29, 29, 36));
  box-shadow: -8px 13px 28px -4px #1d2529;
}
.searchBody {
  background: linear-gradient(to bottom, rgb(32 32 36), rgb(29, 29, 36));
}
.searchResult__item:hover {
  background-color: rgb(43 43 46);
  border-radius: 15px;
  cursor: -webkit-grab;
  cursor: grab;
}
.searchResult__item:hover:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.Search__result_left {
  display: inline;
  justify-content: center;
}

.searchResult__items {
  z-index: 900;
  position: relative;
}
.searchResult__header {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  align-items: flex-start;
  padding: 30px;
  padding: 30px;
  z-index: 999;
}
.header__top__image {
  position: absolute;
  width: -webkit-fill-available;
  top: 0;
  left: 0;
  height: -webkit-fill-available;
}
.searchResult__logo {
  object-fit: contain;
  height: 35px;
  margin-right: 50px;
  cursor: pointer;
  z-index: 9;
  position: relative;
}

.searchResult__headerBody > .search > .search__input {
  margin-top: 0;
  margin: unset;
}

.searchResult__options,
.searchResult__optionsRight,
.searchResult__optionsLeft {
  display: flex;
}

.searchResult__optionsRight {
  margin-left: 80px;
}
.searchResult__itemSnippet,
.searchResult__itemTitle h2 {
  padding-left: 47px;
}
.searchResult__items {
  max-width: 715px;
  margin-top: 20px;
  margin-left: 240px;
  margin-bottom: 100px;
}

.searchResult__itemsCount {
  color: #70757a;
  font-size: 14px;
}

.searchResult__item {
  margin: 40px 0;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 80vh;
}

.searchResult__itemLink {
  display: flex;
  align-items: center;
  color: #202124;
  text-decoration: none;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.searchResult__itemImage {
  height: 87px;
  width: 100px;
  margin-right: 20px;
  object-fit: cover;
  padding-left: 6px;
  position: relative;
  top: -40px;
  border-radius: 69px 60px 51px 47px;
  -moz-border-radius: 69px 60px 51px 47px;
  -webkit-border-radius: 69px 60px 51px 47px;
  border: 0px solid #000000;
}
.title {
  color: whitesmoke;
  width: auto;
  margin-left: 45px;
}
.searchResult__itemTitle {
  text-decoration: none;
}

.searchResult__itemTitle:hover {
  cursor: pointer;
}

.searchResult__itemTitle > h2 {
  font-weight: 500;
  margin: 10px 0;
  font-family: "Rubik", sans-serif;
  color: rgb(142 165 229);
  width: 63vh;
}
.searchResult__itemSnippet {
  color: rgb(232 232 233);
  font-size: 0.995rem;
  width: 663px;
  margin-top: 58px;
  position: relative;
  display: inline-block;
  top: -42px;
}

/* Media Screen */

@media screen and (max-width: 480px) {
  .searchResult__logo {
    height: 17px;
    position: relative;
    top: 21px;
  }
  .search__input {
    width: auto;
    position: relative;
    left: -30px;
  }
  .searchResult__items {
    margin-left: 0px;
  }
  .searchResult__itemsCount {
    margin: 20px;
  }
  .searchResult__header {
    padding: -1px;
  }
  .searchResult,
  .searchResult__header {
    margin-right: 0;
  }
  body {
    margin-left: -20px;
  }
}

* {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
