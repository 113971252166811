@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto+Slab:wght@600&display=swap');

.search__input {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 30px;
    padding: 10px 20px;
    border-radius: 50px;
    width: 75vw;
    max-width: 560px;
    margin: 0 auto;
    margin-top: 40px;
    background-color: rgb(60, 60, 62);
    border-radius: 24px;
    box-shadow: rgb(255 255 255 / 7%) 0px 0.5rem 1.5rem;
    border: 1px solid rgba(83, 83, 110, 0.25);
    transition: box-shadow 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s;
    font-size: 0.875rem;

   

}

.search__input > input {
    flex: 1;
    padding: 10px 20px;
    font-size: medium;
    background-color: transparent;
    color: rgb(248, 248, 248);
    border: none;
    font-family: 'Inter', sans-serif;
    z-index: 9;
}

.search__input > input:focus {
    outline-width: 0;
}

.search__inputIcon {
    color: gray;
}

.search__buttons {
    margin-top: 20px;
    display: flex;
    gap: 2vh;
    justify-content: center;
}

.search__buttons .search__buttons__1 {
    padding: 0.5rem 1.25rem;
    border-radius: 50px;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 18px;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.15px;
    color: rgb(255, 255, 255);
    background: linear-gradient(98.89deg, rgb(50, 50, 253) 0%, rgb(107, 176, 255) 100%);
    margin-top: 18px;
    font-size: 15px;
    font-family: 'Roboto Slab', serif;
}
.search__buttons .search__buttons__2 {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        letter-spacing: -0.15px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 48px;
        border: 1px solid rgba(21, 21, 110, 0.08);
        padding: 0.5rem 1rem;
        color: rgb(248, 248, 248);
        background-color: rgb(43, 43, 46);
        margin-top: 20px;
}

.search__buttons button:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
    background-color: #f8f8f8;
    border: 1px solid #c6c6c6;
    color: #222;
}

.search__buttonHidden {
    display: none !important;
}